/**
 * Page Controller
 *
 * @description Provides a link layer between the cms web pages and the web application
 *
 *  NOTE:  API spec available at: https://app.swaggerhub.com/apis/TiagoLimaRocha/hey-broadband-cms/1.0.0#/
 */

import { logger, LOG } from '@lib/logger';
import { GetPageByIdT, GetPageBySlugT, Theme } from '@lib/types';

const root = process.env.CMS_ROOT_URL;

/**
 * Parses a page and grabs all the row data in the form of a list
 *
 * @param {any} page The page data
 * @returns A list of row data
 */
export const getRows = ({ page }: { page: any }) =>
  page?.rows?.map((data: { type: any }) => {
    return { type: data.type, value: { ...data } };
  }) || [{ type: 'noop', value: {} }];

/**
 * Builds the header data object from the provided pages data
 *
 * @param {any} page The page data containing the theme of  the hero section
 * @param {any} pages The fetched pages data
 * @returns The header object
 */
export const getHeaderData = ({ page, pages }: { page?: any; pages: any }) => ({
  theme: page?.hero?.theme || Theme.LIGHT,
  navInfo: (pages && pages?.map(({ navigation }) => navigation)) || [],
});

/**
 * Fetches the page metadata
 *
 * @param {any} page The page data containing the theme of  the hero section
 * @returns The page metadata
 */
export const getMetadata = ({ page }: { page: any }) => page?.metadata || {};

/**
 * Fetches the hero feature list data
 *
 * @param {any} page The page data containing the theme of  the hero section
 * @returns The page hero feature list data
 */
export const getFeatureList = ({ page }: { page: any }) =>
  page?.rows?.find(({ type }: { type: string }) => type === 'hero')?.featureList || [];

/**
 * Fetches the pages collection from the cms api
 *
 * @returns {Promise<any>} A list of the pages data
 */
export const getPages = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/pages`);

    const pages = await response.json();

    return pages.filter(({ display }) => display);
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_PAGES);

    return null;
  }
};

/**
 * Fetches the coverage pages collection from the cms api
 *
 * @returns {Promise<any>} A list of the coverage pages data
 */
export const getCoveragePages = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/coverage-pages`);

    const pages = await response.json();

    return pages.filter(({ display }) => display);
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_COVERAGE_PAGES);

    return null;
  }
};

/**
 * Fetches the legal pages collection from the cms api
 *
 * @returns {Promise<any>} A list of the coverage pages data
 */
export const getLegalPages = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/legal-pages`);

    const pages = await response.json();

    return pages.filter(({ display }) => display);
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_COVERAGE_PAGES);

    return null;
  }
};

/**
 * Fetches a page with a given id from the pages collection in the cms api
 *
 * @param {number} id The entry id to match against
 * @returns {Promise<any>} The page data
 */
export const getPageById = async ({ id }: GetPageByIdT): Promise<any> => {
  try {
    const response = await fetch(`${root}/pages/${id}`);

    const page = await response.json();

    return page;
  } catch (err) {
    logger.error(
      {
        id,
        err,
      },
      LOG.ERROR.CONTROLLER.PAGE.GET_PAGE_BY_ID,
    );

    return null;
  }
};

/**
 * Fetches a page with a given slug from the pages collection in the cms
 *
 * @param {string} slug The slug to match against
 * @returns {Promise<any>} The page data
 */
export const getPageBySlug = async ({ slug }: GetPageBySlugT): Promise<any> => {
  try {
    const pages = await getPages();

    const page = pages?.find(({ navigation }) => navigation?.slug === slug);

    if (!page) {
      throw new ReferenceError('Cannot find page');
    }

    return page;
  } catch (err) {
    logger.error(
      {
        slug,
        err,
      },
      LOG.ERROR.CONTROLLER.PAGE.GET_PAGE_BY_SLUG,
    );

    return null;
  }
};

/**
 * Fetches a coverage page with a given slug from the pages collection in the cms
 *
 * @param {string} slug The slug to match against
 * @returns {Promise<any>} The coverage page data
 */
export const getCoveragePageBySlug = async ({ slug }: GetPageBySlugT): Promise<any> => {
  try {
    const response = await fetch(`${root}/coverage-pages?slug=${slug}`);

    const page = await response.json();

    if (!page.length) {
      throw new ReferenceError('Cannot find coverage page');
    }

    return page[0];
  } catch (err) {
    logger.error(
      {
        slug,
        err,
      },
      LOG.ERROR.CONTROLLER.PAGE.GET_COVERAGE_PAGE_BY_SLUG,
    );

    return null;
  }
};

/**
 * Fetches a legal page with a given slug from the pages collection in the cms
 *
 * @param {string} slug The slug to match against
 * @returns {Promise<any>} The coverage page data
 */
export const getLegalPageBySlug = async ({ slug }: GetPageBySlugT): Promise<any> => {
  try {
    if (slug.match(/\.js/)) return null;

    const pages = await getLegalPages();

    const page = pages?.find((page: { slug: string }) => page?.slug === slug);

    if (!page) {
      throw new ReferenceError('Cannot find legal page');
    }

    return page;
  } catch (err) {
    logger.error(
      {
        slug,
        err,
      },
      LOG.ERROR.CONTROLLER.PAGE.GET_LEGAL_PAGE_BY_SLUG,
    );

    return null;
  }
};

/**
 * Fetches the coverage listing page data from the cms api
 *
 * @returns {Promise<any>} The page data
 */
export const getCoverageListingPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/coverage-listing-page`);

    const page = await response.json();

    return page;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_COVERAGE_LISTING_PAGE);

    return null;
  }
};

/**
 * Fetches the legal page single type data from the cms api
 *
 * @returns {Promise<any>} The legal page data
 */
export const getLegalPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/legal-page`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_LEGAL_PAGE);

    return null;
  }
};

/**
 * Fetches the checkout page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getCheckoutPage = async (slug = 'checkout'): Promise<any> => {
  try {
    const response = await fetch(`${root}/${slug}`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_CHECKOUT_PAGE);

    return null;
  }
};

/**
 * Fetches the help page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getHelpPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/help-page`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_HELP_PAGE);

    return null;
  }
};

/**
 * Fetches the payment page single type data from the cms api
 *
 * @returns {Promise<any>} The payment page data
 */
export const getPaymentPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/payment`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_PAYMENT_PAGE);

    return null;
  }
};

/**
 * Fetches the pre-order page single type data from the cms api
 *
 * @returns {Promise<any>} The pre-order page data
 */
export const getPreorderPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/preorder-page`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_PREORDER_PAGE);

    return null;
  }
};

/**
 * Fetches the purchase page single type data from the cms api
 *
 * @returns {Promise<any>} The purchase page data
 */
export const getPurchasePage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/purchase`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_PURCHASE_PAGE);

    return null;
  }
};

/**
 * Fetches the register interest page single type data from the cms api
 *
 * @returns {Promise<any>} The register interest page data
 */
export const getRegisterInterestPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/register-interest`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_REGISTER_INTEREST_PAGE);

    return null;
  }
};

/**
 * Fetches the Outage Form page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getReportOutagePage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/report-outage-page`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_OUTAGE_FORM_PAGE);

    return null;
  }
};

/**
 * Fetches the Outage Form page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getOrderConfirmationPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/order-confirmation`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_OUTAGE_FORM_PAGE);

    return null;
  }
};

/**
 * Fetches the Outage Form page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getExtrasPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/extras`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_EXTRAS_PAGE);

    return null;
  }
};

/**
 * Fetches the Blog page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getBlogsPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/blog-page`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_EXTRAS_PAGE);

    return null;
  }
};

/**
 * Fetches the Outage Form page single type data from the cms api
 *
 * @returns {Promise<any>} The checkout page data
 */
export const getPropertyConfirmationPage = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/property-confirmation`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_PROPERTY_CONFIRMATION_PAGE);

    return null;
  }
};

export const createDataLayerEntry = async ({ dataLayer }: { dataLayer: any }): Promise<any> => {
  try {
    const response = await fetch(`${root}/data-layers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dataLayer,
      }),
    });

    const data = await response.json();

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_EXTRAS_PAGE);

    return null;
  }
};

export const getSingleCoveragePageTemplate = async ({
  location,
}: {
  location: any;
}): Promise<any> => {
  try {
    const response = await fetch(`${root}/single-coverage-page/`);

    const rawJson = await response.text();

    const data = JSON.parse(rawJson.replaceAll('~~location~~', location.name));

    return data;
  } catch (err) {
    // logger.error(err, LOG.ERROR.CONTROLLER.PAGE.GET_SINGLE_COVERAGE_PAGE_TEMPLATE);

    return null;
  }
};
