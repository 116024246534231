import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import Media from 'react-media';

import useHeaderContext from '@hooks/useHeaderContext';
import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';

import { Button, Icon, Banner } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { Logo } from '@components/Atoms/Logo/Logo';
import { MiniBasketPopup, SalesCountdown } from '@components/Organisms';

import Close from '@public/icons/Close-white.svg';
import HamburgerBlack from '@public/icons/Hamburger-Menu-Dark.svg';
import BasketHeaderIconBlack from '@public/icons/Basket-Header-Black.svg';

import { StyledHeader, HeaderButton, HeaderMenu, HeaderDropDown } from './styled';
import { breakpoints } from '@theme/breakpoints';
import * as gtag from '@lib/utils/gtag';
import useGAEvent from '@hooks/useGAEvent';
import PhoneIcon from '@public/icons/Phone.svg';
import PhoneWhiteIcon from '@public/icons/Phone-White.svg';

const GetHBButton = () => {
  return (
    <Link href="/address-finder" passHref>
      <Button as="a" buttonType={ButtonType.PRIMARY} className="get-HB flex">
        Switch to H!B
      </Button>
    </Link>
  );
};

const BasketButton = () => {
  const { basketItem: basketItemId, setIsBasketPopupOpen, isBasketPopupOpen } = useBasketContext();

  const gaEvent = useGAEvent();

  useEffect(() => {
    if (isBasketPopupOpen) {
      gaEvent.send({
        action: 'view_cart',
      });
    }
  }, [isBasketPopupOpen]);

  // @todo Remove the get-HB class dependency and put it into StyledButton.
  return (
    <div className="header__basket-button">
      {basketItemId && (
        <button onClick={() => setIsBasketPopupOpen((prev) => !prev)}>
          <Icon sm icon={BasketHeaderIconBlack} />
        </button>
      )}
    </div>
  );
};

export const Header = ({ path = null, inverted = false, showCtaInHeader = true }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [pinned, setPinned] = useState<boolean>(false);
  const { basketItem } = useBasketContext();
  const { navInfo } = useHeaderContext();
  const { isCurrentPostcodeEligible } = usePostcodeContext();
  const ref = useRef(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const navParents: any =
    navInfo
      ?.filter((x) => x)
      ?.filter((navItem) => !navItem?.moreLink)
      ?.sort((x, y) => {
        if (x?.order < y?.order) return -1;

        if (x?.order > y?.order) return 1;

        return 0;
      }) || [];

  const navChildren: any =
    navInfo
      ?.filter((x) => x)
      ?.filter((navItem) => navItem?.moreLink)
      ?.sort((x, y) => {
        if (x?.moreLinkOrder < y?.moreLinkOrder) return -1;

        if (x?.moreLinkOrder > y?.moreLinkOrder) return 1;

        return 0;
      }) || [];

  return (
    <>
      <StyledHeader
        onUnfix={() => {
          if (pinned) {
            setPinned(false);
          }
        }}
        onUnpin={() => {
          setPinned(false);
        }}
        onPin={() => {
          setPinned(true);
        }}
        downTolerance={5}
        $inverted={inverted}
      >
        <Banner className="header-banner" />
        <SalesCountdown />

        <div className="container header__container ">
          <Logo />

          <nav className="header__navigation">
            <Link href={`tel:08081681616`} passHref>
              <HeaderButton as="a" color="var(--colors-primary)">
                <div className="primary-phone">
                  <span>Need help?</span>
                  <Icon xxs icon={PhoneIcon} />
                  <span>0808 168 16 16</span>
                </div>
              </HeaderButton>
            </Link>
            {navParents?.length > 0 &&
              navParents?.map(({ id, display, slug, label }) => {
                if (display) {
                  return (
                    <Link key={id} href={`/${slug}`} passHref>
                      <HeaderButton as="a">
                        <span className={`${'/' + slug === path ? 'font-bold' : ''}`}>{label}</span>
                      </HeaderButton>
                    </Link>
                  );
                }
              })}

            {navChildren?.length > 0 && (
              <div className="relative header__more">
                <HeaderButton
                  className="header-button"
                  as="a"
                  onClick={() => {
                    if (dropdown.current) dropdown.current.focus();
                  }}
                >
                  <span>More...</span>
                </HeaderButton>
                <HeaderDropDown ref={dropdown} className="header-dropdown">
                  <div className="header-dropdown__wrapper">
                    {navChildren?.map(
                      ({ id, display, slug, label }) =>
                        display && (
                          <Link key={id} href={`/${slug}`} passHref>
                            <a href={`/${slug}`}>{label}</a>
                          </Link>
                        ),
                    )}
                  </div>
                </HeaderDropDown>
              </div>
            )}
          </nav>
          {showCtaInHeader && (
            <div className="header__aside">
              <a href="tel:08081681616">
                <>
                  <span>Need help?</span>
                  <Icon xxs icon={PhoneIcon} />
                  <span>0808 168 16 16</span>
                </>
              </a>
              {basketItem ? <BasketButton /> : <GetHBButton />}
              <MiniBasketPopup />
            </div>
          )}

          <div className="header__hamburger">
            <Media queries={breakpoints}>
              {({ tabletMax }) =>
                tabletMax &&
                basketItem &&
                isCurrentPostcodeEligible &&
                showCtaInHeader && <BasketButton />
              }
            </Media>
            <button onClick={() => setMenuOpen(true)} aria-pressed="false">
              <Icon icon={HamburgerBlack} sm altText="Open menu" />
            </button>
          </div>
        </div>

        {/* @todo Need to refactor navigation to have a single navigation list restructured for mobile / desktop */}
        <HeaderMenu open={menuOpen} ref={ref}>
          <div className="container mobile-navigation__header">
            <Logo inverted />
            <div className="flex items-center gap-10">
              <a href="tel:08081681616">
                <>
                  <Icon xxs icon={PhoneWhiteIcon} />
                  0808 168 16 16
                </>
              </a>
              <button onClick={() => setMenuOpen(false)} className="mobile-navigation__close">
                <Icon icon={Close} altText="Close menu" sm />
              </button>
            </div>
          </div>

          <nav className="container mobile-navigation">
            {[navParents, navChildren]?.flat()?.map(
              (navItem) =>
                navItem?.display && (
                  <Link key={navItem?.id} href={`/${navItem?.slug}`} passHref>
                    <a
                      className={`mobile-navigation__link ${
                        navItem?.moreLink ? 'header-more-menu' : ''
                      }`}
                      onClick={() => setMenuOpen(false)}
                      href={`/${navItem?.slug}`}
                    >
                      {navItem?.label}
                    </a>
                  </Link>
                ),
            )}
          </nav>
        </HeaderMenu>
      </StyledHeader>
    </>
  );
};
