import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { breakpoints } from '@theme/breakpoints';

import { CardType, StyledCardT } from '../types';

import MaskRoundedSm from '@public/mask__rounded--sm.png';
import ChevronRight from '@public/icons/Arrow-Right.svg';
import ChevronRightBlue from '@public/icons/Arrow-Right-Blue.svg';
import Hexagon from '@public/hexagon_background.svg';
import HexagonMobile from '@public/hexagon_background_mobile.png';
import HexagonBorder from '@public/hexagon_border.svg';

export const StyledCard = styled.div<StyledCardT>`
  ${({ type }) => {
    const { desktop, tablet } = breakpoints;

    switch (type) {
      case CardType.RECOMMENDATION:
        return css`
          display: flex;
          flex-direction: column;
          margin-top: 1rem;
          transition: all 0.3s ease;

          .card-recommendation {
            &__image {
              filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
              img {
                object-fit: contain;
                mask-size: contain;
                mask-image: url('${MaskRoundedSm.src}');
                mask-repeat: no-repeat;
                mask-position: center;
              }
            }

            &__link {
              font-family: var(--fonts-family-secondary);
              justify-content: start;
              margin-left: 0.5rem;
              text-decoration: none;
              border-radius: 0;
              color: var(--colors-primary);
              font-weight: normal;
              &:after {
                content: '';
                display: inline-block;
                transform: translateY(-1px);
                background: url(${ChevronRightBlue.src}) no-repeat center;
                background-size: 250%;
                width: 10px;
                height: 12px;
                margin-left: ${rem(8)};
              }
            }
          }
        `;

      case CardType.COVERAGE:
      case CardType.COVERAGE_MOBILE_MINIMAL:
        return css`
          position: relative;
          height: 100%;
          min-width: 19rem;
          background-color: white;
          border: 1px solid var(--colors-primary-15);
          border-radius: 0.75rem;
          overflow: hidden;
          transition: all 0.3s ease;
          filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));

          ${
            type === CardType.COVERAGE_MOBILE_MINIMAL &&
            css`
              @media ${breakpoints.mobilelgMax} {
                min-width: 12rem;
              }
            `
          };

            .card-coverage__image {
              position: relative;
              height: 210px;
              width: 100%;
              overflow: hidden;
              ${
                type === CardType.COVERAGE_MOBILE_MINIMAL &&
                css`
                  @media ${breakpoints.mobilelgMax} {
                    display: none;
                  }
                `
              };

              img {
                object-fit: contain;
              }
            }        
          }

          .card-coverage__title {
            font-size: ${rem(20)};
            text-decoration: none;
            color: var(--colors-grey-100);
            font-weight: 500;
            text-transform: capitalize;
            font-family: var(--fonts-family-secondary);
            @media ${breakpoints.tablet} {
              font-size: ${rem(24)};
            }
            &:after {
              content: '';
              display: inline-block;
              vertical-align: middle;
              background: url(${ChevronRight.src}) no-repeat center;
              background-size: 250%;
              width: 10px;
              height: 15px;
              margin-left: ${rem(8)};
            }
          }

          .card-coverage__cta {
            margin-top: 0.5rem;
          }

          .card-coverage__body {
            position: relative;
            padding: 1rem;
            width: 100%;
            > p {
              font-size: ${rem(14)};
              margin: 0.5rem 0 0.8rem;
            }

            @media ${breakpoints.tablet} {
              position: static;
              padding-top: 1.5rem;
              padding-right: 2rem;
              > p {
                font-size: ${rem(16)};
                font-family: var(--fonts-family-secondary);
                font-weight: 300;
                color: black;
              }
            }
            & button {
              color: #2e2e44;
              font-weight: 600;
              font-size: 1.5rem;
              margin-bottom: 0.5rem;
            }
          }

          .card-coverage__service-status {
            display: block;
            width: fit-content;
            margin-top: ${rem(16)};
            margin-right: 1rem;
            border-radius: 1rem;
            font-size: ${rem(14)};
            padding: ${rem(2)} ${rem(20)};
            ${
              type === CardType.COVERAGE_MOBILE_MINIMAL &&
              css`
                @media ${breakpoints.mobilelgMax} {
                  margin-bottom: 0;
                }
              `
            };

            &--red {
              background-color: var(--colors-secondary-15);
              color: var(--colors-secondary);
            }
            &--blue {
              background-color: #dce3fb;
              color: var(--colors-primary);
            }
            &--green {
              background-color: #dcfce7;
              color: #15803d;
            }
            &--yellow {
              background-color: #fef9c3;
              color: #ca8a04;
            }
        `;

      case CardType.ICON:
        return css`
          // max-width: 80%;
          border: none;

          .card-icon {
            &__image {
              width: auto;
              height: 3.5rem;
              margin-top: 0.5rem;
            }
            &__heading {
              text-align: left;
              margin-bottom: 1rem;
              font-size: var(--fonts-sizing-primary);
            }
            &__body {
              text-align: left;
              line-height: 1.6;
              color: var(--colors-grey-50);
              font-size: var(--fonts-sizing-small);
              margin-top: 10px;
              padding-right: 25px;
            }
          }
        `;

      case CardType.IMAGE:
        return css`
          color: #565661;
          transition: all 0.3s ease;

          &.row-reverse {
            flex-direction: row-reverse;

            @media ${desktop} {
              .card-image__wrapper {
                padding-right: 0;
              }
            }
          }

          .hex_border {
            height: 30rem;
            width: 100%;
            max-width: 30rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            background-image: url('${HexagonBorder.src}');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 90%;
            animation-name: shakeOut;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;
            animation-name: shake;
            animation-duration: 2s;
            animation-fill-mode: both;
          }

          @keyframes shakeOut {
            5% {
              background-size: 100%;
            }
            15% {
              background-size: 98%;
            }
            30% {
              background-size: 96%;
            }
            50% {
              background-size: 98%;
            }
            100% {
              background-size: 90%;
            }
          }

          @keyframes shake {
            60% {
              background-size: 100%;
            }
            70% {
              background-size: 95%;
            }
            80% {
              background-size: 100%;
            }
            90% {
              background-size: 95%;
            }
            100% {
              background-size: 100%;
            }
          }

          .hex {
            background-image: url('${HexagonMobile.src}');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            height: 26rem;
            width: 90%;
            max-width: 26rem;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            @media ${desktop} {
              width: auto;
              min-width: 520px;
              background-image: url('${Hexagon.src}');
            }
          }

          .card-image {
            &__wrapper,
            &__copy {
              width: 100%;
              @media ${breakpoints.desktop} {
                margin-bottom: 0;
              }
            }

            &__wrapper {
              margin-bottom: 2rem;
              @media ${breakpoints.desktop} {
                margin-right: 2rem;
              }
            }

            &__copy {
              @media ${breakpoints.desktop} {
                margin-left: 4rem;
              }
            }

            &__image {
              width: 100%;
              display: flex;
              justify-content: center;
              span {
                width: 100% !important;
              }
              & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        `;
    }
  }}
`;
