import { rowItem } from '@factories/RowFactory/RowFactory';

//  NOTE:  Enums

export enum IconType {
  MAIL_CIRCLE = 'Mail_Circle',
  MESSAGE_CIRCLE = 'Message_Circle',
  PHONE_CIRCLE = 'Phone_Circle',
}

export enum LinkType {
  DEFAULT = 'default',
  LEGAL = 'legal',
}

export enum PageType {
  PAGE = 'page',
  COVERAGE_PAGE = 'coverage_page',
  LEGAL_PAGE = 'legal_page',
}

export enum Event {
  POSTCODE_CHECKER = 'postcodeChecker',
  GO_TO_PACKAGES = 'goToPackages',
  EXTERNAL_LINK = 'externalLink',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ImagePosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum Layout {
  INTRINSIC = 'intrinsic',
  FIXED = 'fixed',
  RESPONSIVE = 'responsive',
  FILL = 'fill',
}

export enum Format {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  THUMBNAIL = 'thumbnail',
}

export enum Action {
  SIGNUP = 'SIGNUP',
  ROI = 'ROI',
  PREORDER = 'PREORDER',
  LEAD = 'LEAD',
  PROSPECT = 'PROSPECT',
  UPDATE = 'UPDATE',
  PREORDERDDSDU = 'PREORDERDDSDU',
  PREORDERDDMDU = 'PREORDERDDMDU',
}

export enum ServiceStatus {
  ALL = 'All',
  AVAILABLE = 'Available',
  BUILDING = 'Building',
  COLLECTING_INTEREST = 'CollectingInterest',
  COMING_SOON = 'ComingSoon',
}

export enum CoverageRegion {
  ALL = 'all',
  WEST_SUSSEX = 'westSussex',
  GREATER_LONDON = 'greaterLondon',
  BUCKINGHAMSHIRE = 'buckinghamshire',
  OXFORDSHIRE = 'oxfordshire',
  SURREY = 'surrey',
  HAMPSHIRE = 'hampshire',
  HERTFORDSHIRE = 'hertfordshire',
}

export enum UprnStatus {
  PASSSERV = 'PASSSERV',
  NOTPASSED = 'NOTPASSED',
  DESIGN = 'DESIGN',
  INPROGRESS = 'INPROGRESS',
  PASSED = 'PASSED',
  CUSTOMER = 'CUSTOMER',
  STOPED = 'STOPED',
  PREINSTALLED = 'PREINSTALLED',
  INSTALLED = 'INSTALLED',
  CERTIFIED = 'CERTIFIED',
}

export enum MDUStatus {
  STARTED = 'STARTED',
  ADVANCED = 'ADVANCED',
  RFS = 'RFS',
}

//  NOTE:  Interfaces

export interface RowComponentI {
  id: number;
  __component: string;
  type: string;
  display?: boolean;
}

//  NOTE:  Types

export type ProductT = {
  id_product: string;
  name: string;
  display_name: string;
  description: string;
  display_benefit_tag: string | null;
  display_preorder_benefit_tag: string | null;
  monthly_price: string;
  preorder_montly_price: string;
  monthly_price_currency: string | null;
  first_charge: string;
  first_charge_currency: string | null;
  call_charges: string | null;
  months: number;
  created_on: string;
  created_by: string | null;
  modified_on: string | null;
  modified_by: string | null;
  sla: string | null;
  address_type: string;
  category: string | null;
  workorder_type: string | null;
  register_channel: string;
  priority: number | string | null;
  speed: number;
  not_renew_price: string;
  product_type: string | null;
  enabled_for_web: boolean | null;
  promo: string;
  addons?: Array<PromoT>;
};

export type PromoT = {
  id_promotion: string;
  code: string;
  description: string;
  promotion_type: string;
  promotion_value: number;
  text_to_show: string;
  start_date: string;
  end_date: string;
  created_on: string;
  created_by: string | null;
  modified_on: string | null;
  modified_by: string | null;
  postcode: string;
  auto: string | number | null;
  uprn_status: string | number | null;
  products: string;
  until_date_value: string | null;
  register_channel: any | null;
} | null;

export type UprnItemT = {
  uprn: string;
  address: string;
  address_type: string;
  postcode: string;
  coverage: UprnStatus;
  ismdu: boolean;
} | null;

export type UprnDataT =
  | (UprnItemT & {
      street_name: string;
      building_number: string;
      town: string;
      pcns: any | null;
      class: any | null;
      the_geom: string;
      lat: number;
      lon: number;
      created_on: string;
      created_by: string | null;
      modified_on: string | null;
      modified_by: string | null;
      splitterbox: string;
      splitterbox_address: string;
      project: string;
      sp_info1: any | null;
      sp_info2: any | null;
      sp_info3: any | null;
      sp_info4: any | null;
      oss_ismdu: any | null;
      apx_ismdu: boolean;
      mdu_status: MDUStatus;
    })
  | null;

export type ActionArgsT = {
  email: string;
  phone?: string;
  uprn?: string | null | undefined;
  product?: string;
  contact_title?: string;
  contact_name?: string;
  contact_surname?: string;
  pending_direct_debit?: boolean;
  marketing?: string;
  emergency_phone?: string;
  company_name?: string;
  company_number?: string;
  voucher?: boolean;
  promotion?: Array<string>;
  origin?: string;
  current_speed?: string;
  current_contract?: string;
  ending_current?: string;
  contract_date?: string;
  current_provider?: string;
  address_type?: string;
  street_name?: string;
  building_number?: string;
  town?: string;
  postcode?: string;
  scheddule_day?: string;
  scheddule_slot?: string;
};

export type DirectDebitArgsT = {
  code: string;
  account_name: string;
  account_number: string;
  sort_code: string;
};

export type CustomerInfoT =
  | {
      status_description: string;
      base_status: string;
      promotion_description: string | null;
      id_customer: string;
      contact_title: string | null;
      contact_name: string;
      contact_surname: string;
      status: string;
      uprn: string;
      customer_type: string | null;
      company_name: string | null;
      company_vat_number: string | number | null;
      company_billing_address: string | null;
      company_no_of_premises: string | number | null;
      company_it_users: any | null;
      registration_about_us: string;
      registration_date: string;
      registration_signup_date: string;
      registration_promotion: any | null;
      registration_channel: string;
      marketing: any | null;
      id_salesperson: string;
      current_contract: string | null;
      ending_current: string | null;
      contract_date: string | null;
      current_provider: string | null;
      comments: string | null;
      created_on: string;
      created_by: string | null;
      modified_on: string | null;
      modified_by: string | null;
      address_type: string;
      street_name: string;
      building_number: string;
      postcode: string;
      town: string;
      code: string;
      origin: string | null;
      email: string;
      phone: string;
      status_date: string;
      provisioning_id: string;
      emergency_phone: boolean;
      company_number: string | number | null;
      current_speed: string | number | null;
      id_mdu: string | number | null;
      signup_channel: string;
      gdpr_phone: boolean;
      gdpr_email: boolean;
      directdebit_status: string | null;
      directdebit_ext_id: string | null;
      current_cost: string | null;
    }
  | {}
  | null;

export type ScheduleInfoT =
  | {
      id_workorder: string;
      customer: {
        code: string;
        contact_title: string;
        contact_name: string;
        contact_surname: string;
      };
      address: {
        street_name: string;
        building_number: string;
        postcode: string;
        town: string;
      };
      calendar: [{}];
      scheduling_date: string;
      appointment_range: string;
    }
  | {}
  | null;

export type ScheduleResT =
  | {
      id_workorder: string;
      id_operator: string;
      day: string;
      time: string;
      areas: string | string[];
      active: boolean | number | string | null;
      id_user: string;
      id_company: string;
    }
  | {}
  | null;

export type GetPageByIdT = {
  id: number;
};

export type GetPageBySlugT = {
  slug: string;
};

export type RowT = {
  type: typeof rowItem;
  value: any;
};

export type ComponentT =
  | boolean
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal
  | null
  | undefined;

export type FormatT = {
  ext?: string;
  hash?: string;
  height?: number;
  mime?: string;
  name?: string;
  path?: null | any;
  size?: number;
  url?: string;
  width?: number;
};

export type ImageT = {
  id?: number;
  name?: string;
  alternativeText?: string;
  caption?: string;
  url?: string;
  previewUrl?: null | any;
  provider?: string;
  provider_metadata?: null | any;
  created_at?: string;
  updated_at?: string;
};

export type ImageDataT = ImageT &
  FormatT & {
    format?: Format;
    formats?: FormatT[] | any;
    layout?: Layout;
    image?: ImageT;
    className?: string;
  };

export type OrderT = {
  orderNumber: string;
  email: string;
  customerCode: string;
  action: string;
  productId?: string | null;
};

export type BasketItemT = string;

export type GoCardlessDirectDebitArgsT = {
  code?: string;
  mandate_request: boolean;
  payment_request_amount: string | number;
  payment_request_description: string;
  redirect_url: string;
};

export type GoCardlessEventT = {
  id: string;
  created_at: string;
  resource_type: string;
  action: string;
  links: {
    payment: string;
  };
  details: {
    origin: string;
    cause: string;
    scheme: string;
    reason_code: string;
    description: string;
  };
  metadata: {
    ga_client_id?: string;
  };
};

export type GoCardlessEventsT = {
  events: Array<GoCardlessEventT>;
};
