import styled from 'styled-components';
import { StyledAccordionItemProps } from '../types';
import ArrowDownBlue from '@public/icons/Arrow-Down-blue.svg';
import { breakpoints } from '@theme/breakpoints';

export const StyledAccordionWrapper = styled.div`
  border: none;
  transform-style: preserve-3d;
  &:before {
    content: '';
    position: absolute;
    inset: 10px;
    filter: blur(15px);
    transform: translate3d(0px, 15px, -1px);
    border-radius: inherit;
    pointer-events: none;
    background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
  }
  &:last-child > div {
    margin-bottom: 0;
  }
`;

export const StyledAccordionRightImage = styled.div`
  position: relative;
  overflow: hidden;
`;

export const StyledAccordionItem = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 10px;
`;

export const StyledAccordionContent = styled.div<StyledAccordionItemProps>`
  transition: height 0.3s ease;
  overflow: hidden;
  height: 100%;
  border-radius: 0 0 10px 10px;
  .accordion-item {
    border-radius: 10px;
    &__content {
      text-align: left;
      background-color: #ffffff;
      padding: 0 2rem 2rem;
    }
    &__content > ol {
      padding-left: 1.25rem;
      counter-reset: li;
    }
    &__content > ol li {
      counter-increment: li;
    }
    &__content > ol li::before {
      content: counter(li);
      color: #0038ff;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      font: bold bold bold Geomanist;
    }
  }
`;

export const StyledAccordionButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 1rem 2rem;
  padding-right: 5rem;
  background-color: #f7f7ff;
  border-radius: 10px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  font-family: var(--font-geomanist);

  &:hover {
    transition: all 0.2s ease;
    background-color: var(--colors-white);
  }
  &:before {
    content: '';
    width: 70px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-10%);
    background: url('${ArrowDownBlue.src}') no-repeat;
    background-size: 3rem;
    background-position: center;
    transition: all 0.2s ease;
    transform-origin: center;
    @media ${breakpoints.tablet} {
      background-size: 4rem;
    }
  }
  &.open {
    background-color: #ffffff;
    text-align: left;
    border-radius: 10px 10px 0 0;
    &:before {
      transform: rotate(180deg) translateX(10%);
    }
  }
`;
