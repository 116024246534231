import styled from 'styled-components';
import { rem } from 'polished';

export const HeaderMenu = styled.div<any>`
  display: ${({ open }: any) => (open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--colors-primary-bright);
  flex-direction: column;
  color: var(--colors-white);
  padding-bottom: ${rem(38)};
  // @todo Need to refactor navigation to have a single navigation list restructured for mobile / desktop
  .mobile-navigation {
    padding: ${rem(20)} ${rem(25)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &__link {
      padding: ${rem(8)} 0;
      width: 100%;
      font-size: ${rem(30)};
      font-weight: bold;
    }
    &__close {
      margin: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem 1rem;
      align-items: center;

      a[href*='tel'] {
        position: relative;
        top: 0.15rem;
        display: flex;
        gap: 0.5rem;
      }
    }
  }
`;
