export enum EAPIROUTES {
  DIRECT_DEBIT_INVALID_CODE = 'routes.api.direct_debit.invalid_body_data.code.error',
  DIRECT_DEBIT_INVALID_ACCOUNT_NAME = 'routes.api.direct_debit.invalid_body_data.account_name.error',
  DIRECT_DEBIT_INVALID_ACCOUNT_NUMBER = 'routes.api.direct_debit.invalid_body_data.account_number.error',
  DIRECT_DEBIT_INVALID_SORT_CODE = 'routes.api.direct_debit.invalid_body_data.sort_code.error',

  CUSTOMER_SIGNUP_INVALID_CONTACT_NAME = 'routes.api.customer_signup.invalid_body_data.contact_name.error',
  CUSTOMER_SIGNUP_INVALID_CONTACT_SURNAME = 'routes.api.customer_signup.invalid_body_data.contact_surname.error',
  CUSTOMER_SIGNUP_INVALID_EMAIL = 'routes.api.customer_signup.invalid_body_data.email.error',
  CUSTOMER_SIGNUP_INVALID_PHONE = 'routes.api.customer_signup.invalid_body_data.phone.error',
  CUSTOMER_SIGNUP_INVALID_BIRTHDATE = 'routes.api.customer_signup.invalid_body_data.birthdate.error',

  CUSTOMER_PROSPECTS_INVALID_EMAIL = 'routes.api.customer_prospects.invalid_body_data.email.error',
  CUSTOMER_PROSPECTS_INVALID_PHONE = 'routes.api.customer_prospects.invalid_body_data.phone.error',
  CUSTOMER_PROSPECTS_INVALID_UPRN = 'routes.api.customer_prospects.invalid_body_data.uprn.error',

  CUSTOMER_UPDATE_INVALID_EMERGENCY_PHONE = 'routes.api.customer_update.invalid_body_data.emergency_phone.error',
  CUSTOMER_UPDATE_INVALID_MEDICAL_ALARM = 'routes.api.customer_update.invalid_body_data.medical_alarm.error',

  CUSTOMER_ROI_INVALID_CONTACT_NAME = 'routes.api.customer_roi.invalid_body_data.contact_name.error',
  CUSTOMER_ROI_INVALID_CONTACT_SURNAME = 'routes.api.customer_roi.invalid_body_data.contact_surname.error',
  CUSTOMER_ROI_INVALID_EMAIL = 'routes.api.customer_roi.invalid_body_data.email.error',
  CUSTOMER_ROI_INVALID_PHONE = 'routes.api.customer_roi.invalid_body_data.phone.error',
  CUSTOMER_ROI_INVALID_BIRTHDATE = 'routes.api.customer_roi.invalid_body_data.birthdate.error',

  CUSTOMER_PREORDER_INVALID_CONTACT_NAME = 'routes.api.customer_preorder.invalid_body_data.contact_name.error',
  CUSTOMER_PREORDER_INVALID_CONTACT_SURNAME = 'routes.api.customer_preorder.invalid_body_data.contact_surname.error',
  CUSTOMER_PREORDER_INVALID_EMAIL = 'routes.api.customer_preorder.invalid_body_data.email.error',
  CUSTOMER_PREORDER_INVALID_PHONE = 'routes.api.customer_preorder.invalid_body_data.phone.error',
  CUSTOMER_PREORDER_INVALID_BIRTHDATE = 'routes.api.customer_preorder.invalid_body_data.birthdate.error',

  UPFRONT_PAYMENT_REQUEST_INVALID_CODE = 'routes.api.upfront_payment_request.invalid_body_data.code.error',
  UPFRONT_PAYMENT_REQUEST_INVALID_REQUEST_AMOUNT = 'routes.api.upfront_payment_request.invalid_body_data.request_amount.error',
  UPFRONT_PAYMENT_REQUEST_INVALID_REQUEST_DESCRIPTION = 'routes.api.upfront_payment_request.invalid_body_data.request_description.error',
  UPFRONT_PAYMENT_REQUEST_INVALID_REDIRECT_URL = 'routes.api.upfront_payment_request.invalid_body_data.redirect_url.error',
  CUSTOMER_GET_BY_CODE = 'routes.api.customer_get.invalid_body_data.code.error',

  ACTIVE_PROMOS_NO_PROMOS = 'routes.api.active_promos.no_promos.error',

  GET_PROMO_NO_CODE = 'routes.api.get_promo.no_code_provided.error',
  GET_PROMO_NO_PROMOS = 'routes.api.get_promo.no_available_promos.error',
  GET_PROMO_TOO_MANY_PROMOS = 'routes.api.get_promo.too_many_promos.error',
  GET_PROMO_NO_PROMO = 'routes.api.get_promo.no_promo.error',

  UPRN_BY_POSTCODE_NO_POSTCODE = 'routes.api.uprn_by_postcode.no_postcode_provided.error',
  UPRN_BY_POSTCODE_NO_POSTCODES = 'routes.api.uprn_by_postcode.no_postcodes.error',

  UPRN_NOT_FOUND = 'routes.api.uprn.no_uprn_provided.error',
  UPRN_NO_DATA = 'routes.api.uprn.no_uprn_data.error',

  OUTAGE_FORM_NO_EMAIL = 'routes.api.outage.no_email_provided.error',

  UPRN_CHECKED_NO_UPRN = 'routes.api.uprn_checked.no_uprn_provided.error',

  PROMOS_BY_POSTCODE_NO_POSTCODE = 'routes.api.promos_by_postcode.no_postcode_provided.error',

  INSTALLATION_SLOTS_NO_DATA = 'routes.api.installation_slots.no_data.error',

  GOCARDLESS_EVENT_INVALID_DATA = 'routes.api.gocardless_event.invalid_data.error',

  SET_SCHEDULE_INVALID = 'routes.api.set_schedule.invalid_data.error',
}

export enum EGEOCODECONTROLLER {
  GET_CITY_GB = 'controllers.geocode_controller.get_city_gb.error',
  GET_POSTCODES_BY_COORDS_GB = 'controllers.geocode_controller.get_postcodes_by_coords_gb.error',
}

export enum EGLOBALCONTENTCONTROLLER {
  GET_FOOTER = 'controllers.global_content_controller.get_footer.error',
  GET_METADATA = 'controllers.global_content_controller.get_metadata.error',
  GET_PROMO_BANNER = 'controllers.global_content_controller.get_promo_banner.error',
  GET_SALES_COUNTDOWN = 'controllers.global_content_controller.get_sales_countdown.error',
}

export enum EPAGECONTROLLER {
  GET_PAGES = 'controllers.page_controller.get_pages.error',
  GET_COVERAGE_PAGES = 'controllers.page_controller.get_coverage_pages.error',
  GET_LEGAL_PAGES = 'controllers.page_controller.get_legal_pages.error',
  GET_PAGE_BY_ID = 'controllers.page_controller.get_page_page_by_id.error',
  GET_PAGE_BY_SLUG = 'controllers.page_controller.get_page_by_slug.error',
  GET_COVERAGE_PAGE_BY_SLUG = 'controllers.page_controller.get_coverage_page_by_slug.error',
  GET_LEGAL_PAGE_BY_SLUG = 'controllers.page_controller.get_legal_page_by_slug.error',
  GET_COVERAGE_LISTING_PAGE = 'controllers.page_controller.get_coverage_listing_page.error',
  GET_CHECKOUT_PAGE = 'controllers.page_controller.get_checkout_page',
  GET_PAYMENT_PAGE = 'controllers.page_controller.get_payment_page',
  GET_PREORDER_PAGE = 'controllers.page_controller.get_preorder_page',
  GET_PURCHASE_PAGE = 'controllers.page_controller.get_purchase_page',
  GET_HELP_PAGE = 'controllers.page_controller.get_help_page',
  GET_LEGAL_PAGE = 'controllers.page_controller.get_legal_page',
  GET_REGISTER_INTEREST_PAGE = 'controllers.page_controller.get_register_interest_page',
  GET_OUTAGE_FORM_PAGE = 'controllers.page_controller.get_outage_form_page',
  GET_EXTRAS_PAGE = 'controllers.page_controller.get_extras_page',
  GET_PROPERTY_CONFIRMATION_PAGE = 'controllers.page_controller.get_property_confirmation_page',
}

export enum EPRODUCTCONTROLLER {
  GET_PRODUCTS = 'controllers.product_controller.get_products.error',
  GET_ACTIVE_PROMOS = 'controllers.product_controller.get_active_promos.error',
  GET_PROMO_BY_CODE = 'controllers.product_controller.get_promo_by_code.error',
  GET_PROMO_BY_POSTCODE = 'controllers.product_controller.get_promo_by_postcode.error',
  GET_UPRN_LIST = 'controllers.product_controller.get_uprn_list.error',
  GET_UPRN_DATA = 'controllers.product_controller.get_uprn_data.error',
  SET_UPRN_CHECKED = 'controllers.product_controller.setUprnChecked.error',
  CHECK_POSTCODE = 'controllers.product_controller.check_postcode.error',
  DO_ACTION = 'controllers.product_controller.do_action.error',
  GET_CUSTOMER_INFO = 'controllers.product_controller.get_customer_info.error',
  RENEW = 'controllers.product_controller.renew.error',
  OUTAGESERVER = 'controllers.product_controller.outageServer',
  OUTAGE = 'controllers.product_controller.outage',
  SET_DIRECT_DEBIT = 'controllers.product_controller.set_direct_debit.error',
  GET_SCHEDULE_INFO = 'controllers.product_controller.get_schedule_info.error',
  SET_SCHEDULE_INFO = 'controllers.product_controller.set_schedule_info.error',
  INSTALLATION_SLOTS = 'controllers.product_controller.installation_slots.error',
  INSTALLATION_SLOTS_SERVER = 'controllers.product_controller.installation_slots_server.error',
  GOCARDLESS_URL_SERVER = 'controllers.product_controller.gocardless_url_server.error',
  GOCARDLESS_URL = 'controllers.product_controller.gocardless_url.error',
  CUSTOMER_UPFRONT_PAYMENT_REQUEST_INVALID_CODE = 'controllers.product_controller.customer_upfront_payment_request.invalid_code.error',
  CUSTOMER_UPFRONT_PAYMENT_REQUEST_INVALID_REQUEST_AMOUNT = 'controllers.product_controller.customer_upfront_payment_request.invalid_request_amount.error',
  CUSTOMER_UPFRONT_PAYMENT_REQUEST_INVALID_REQUEST_DESCRIPTION = 'controllers.product_controller.customer_upfront_payment_request.invalid_request_description.error',
  CUSTOMER_UPFRONT_PAYMENT_REQUEST_INVALID_REDIRECT_URL = 'controllers.product_controller.customer_upfront_payment_request.invalid_redirect_url.error',
}

export enum EROWFACTORY {
  CREATE_ROW = 'factories.row_factory.create_row.error',
}
