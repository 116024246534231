import { logger, LOG } from '@lib/logger';
import { object } from 'yup';

const root = process.env.CMS_ROOT_URL;

/**
 * Fetches the blogs collection from the cms api
 *
 * @returns {Promise<any>} A list of the blogs collection
 */
export const getAllBlogs = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/blogs?_sort=published_at:DESC`);

    const blogs = await response.json();

    return blogs;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.GET_ALL_BLOGS);

    return null;
  }
};

/**
 * Fetches the a single blog from the cms api
 *
 * @param {string} slug The entry slug to match against
 * @returns {Promise<any>} A list of the blog collection
 */
export const getBlog = async ({ slug }): Promise<any> => {
  try {
    const response = await fetch(`${root}/blogs?slug=${slug}`);

    const blog = await response.json();
    return blog[0] || null;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.GET_BLOG);

    return null;
  }
};

/**
 * Fetches the categories collection from the cms api
 *
 * @returns {Promise<any>} A list of the categories collection
 */
export const getAllCategories = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/categories`);

    const categories = await response.json();

    return categories;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.CATEGORY.GET_ALL_CATEGORIES);

    return null;
  }
};

/**
 * Fetches all blogs of a certain category from the cms api
 *
 * @param {string} category_name The category_name to match against
 * @returns {Promise<any>} All blogs that match the category_name
 */
export const getBlogsByCategory = async ({ categories }): Promise<any> => {
  try {
    const catString = categories.join('&categories.category_name=');
    const response = await fetch(`${root}/blogs?categories.category_name=${catString}`);

    const relatedBlogs = await response.json();
    relatedBlogs.sort((a, b) => {
      return new Date(b.published_at).getTime() - new Date(a.published_at).getTime();
    });

    return relatedBlogs;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.BLOGS_BY_CATEGORY);
    return null;
  }
};

/**
 * Fetches the community-posts collection from the cms api
 *
 * @returns {Promise<any>} A list of the blogs collection
 */
export const getAllCommunityPosts = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/community-posts?_sort=published_at:DESC`);

    const blogs = await response.json();

    return blogs;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.GET_ALL_BLOGS);

    return null;
  }
};

/**
 * Fetches the community-posts by coverage-page collection from the cms api
 *
 * @param {string} slug The entry slug to match against
 * @returns {Promise<any>} A list of the blog collection
 */

export const getCommunityPostsByCoveragePage = async ({ slug }): Promise<any> => {
  try {
    const response = await fetch(`${root}/community-posts?coveragePages.slug=${slug}`);

    const results = await response.json();

    results.forEach((element) => {
      element['postType'] = 'community-posts';
    });

    return results;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.GET_BLOG);

    return null;
  }
};

/**
 * Fetches the a single community-post from the cms api
 *
 * @param {string} slug The entry slug to match against
 * @returns {Promise<any>} A list of the blog collection
 */

export const getCommunityPost = async ({ slug }): Promise<any> => {
  try {
    const response = await fetch(`${root}/community-posts?slug=${slug}`);

    const blog = await response.json();
    return blog[0] || null;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.GET_BLOG);

    return null;
  }
};

/**
 * Fetches the blog posts by coverage page from the cms api
 *
 * @param {string} slug The entry slug to match against
 * @returns {Promise<any>} A list of the blog collection
 */
export const getBlogPostsByCoveragePage = async ({ slug }): Promise<any> => {
  try {
    const response = await fetch(`${root}/blogs?coveragePages.slug=${slug}`);

    const blog = await response.json();
    return blog;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.BLOGS.GET_BLOG);

    return null;
  }
};
