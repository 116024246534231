import React from 'react';

import { getFormattedPrice, getFriendlyProductTitle } from '@lib/utils';

import StyledMiniBasketContents from './styled/StyledMiniBasketContents';
import StyledMiniBasketContentsRow from './styled/StyledMiniBasketContentsRow';

import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { PromoCode } from '../PromoCode/PromoCode';
import { BasketContentsI } from '@hoc/WithBasketContents/BasketContentsInterface';
import useGAEvent from '@hooks/useGAEvent';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const PromoTitle = styled.strong`
  font-size: 1.063rem;
  color: var(--colors-text-primary);
  color: var(--colors-grey-100);
  font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';

  @media ${breakpoints.desktop} {
    font-size: 1.313rem;
  }
`;

export const MiniBasketContents = ({
  basketItem,
  addonsProducts = [],
  promoCodes = [],
  formattedPrice,
  originalBasketItemPrice,
  addonsMonthlyFormattedPrices,
  addonsOriginalFormattedPrices,
  totalMonthlyCost,
  oneOffChargesDiscounted,
}: BasketContentsI) => {
  const { removePromoCode, removeAddon } = useBasketContext();
  const { postcodeItem } = usePostcodeContext();

  const { name, display_name, category, speed, months, promo } = basketItem;

  const gaEvent = useGAEvent();

  //@promo-debug
  // again, checks if promo is valid based on uprn status / coverage
  const validPromoCodes = promoCodes.map((promo) => {
    return {
      ...promo,
      valid: !(
        promo.uprn_status !== null &&
        !!postcodeItem?.coverage &&
        !promo.uprn_status.includes(postcodeItem?.coverage)
      ),
    };
  });

  return (
    <>
      <StyledMiniBasketContents>
        <StyledMiniBasketContentsRow $firstChild>
          <div>
            <strong>Address</strong>
            <br />
            <p className="row__info">{postcodeItem?.address}</p>
          </div>
        </StyledMiniBasketContentsRow>
        <StyledMiniBasketContentsRow $firstChild>
          <div>
            <strong>Plan</strong>
            <br />
            <p className="row__info">
              {getFriendlyProductTitle({ name, display_name, category })} {speed}
            </p>
          </div>
        </StyledMiniBasketContentsRow>
        <StyledMiniBasketContentsRow $firstChild>
          <div>
            <strong>Contract</strong>
            <br />
            <div className="row__info">
              {originalBasketItemPrice && originalBasketItemPrice !== formattedPrice ? (
                <del className="text-[.875em]">{`£${originalBasketItemPrice}`}</del>
              ) : (
                ''
              )}{' '}
              <span className="row__cost-pm">{`£${formattedPrice}`}</span>
              <span className="row__info__month">/month</span>{' '}
              {months > 0 ? `for ${months} months` : `30 Days Rolling Contract`}
            </div>
            {promo && promoCodes && promoCodes.length && <strong>{promo}</strong>}
          </div>
          {/* <div className="remove-container">
            <span>{`£${formattedPrice}`}</span>
            <Button buttonType={ButtonType.ICON} className="remove-button" onClick={clearBasket}>
              Edit
            </Button>
          </div> */}
        </StyledMiniBasketContentsRow>
        {addonsProducts.length > 0 ? (
          <StyledMiniBasketContentsRow>
            <div>
              <strong>Extras</strong>
              {addonsProducts?.map((addon: any, index) => (
                <p key={addon?.id_product} className="row__info">
                  <span className="row__extra">
                    {!addon?.name.includes('Home Phone')
                      ? getFriendlyProductTitle({ ...addon })
                      : addon?.name?.split(' - ')[0]}
                  </span>
                  &nbsp;
                  {addonsOriginalFormattedPrices[index] && (
                    <span className="original-price">{`£${addonsOriginalFormattedPrices[index]}`}</span>
                  )}
                  &nbsp;
                  {`£${addonsMonthlyFormattedPrices[index]}`}
                  <span className="row__info__month">/month</span>
                  <Button
                    buttonType={ButtonType.HYPERLINK}
                    className="remove-button row__info"
                    onClick={() => {
                      removeAddon(addon.id_product);

                      gaEvent.send({
                        action: 'remove_item',
                        parameters: {
                          selected_extras: gaEvent.parameters.extras.filter(
                            (extra) => extra?.id_product !== addon.id_product,
                          ),
                        },
                      });
                    }}
                  >
                    Remove
                  </Button>
                </p>
              ))}
            </div>
          </StyledMiniBasketContentsRow>
        ) : null}
        {validPromoCodes && !!validPromoCodes.length && (
          <>
            <PromoTitle>Promos</PromoTitle>
            {validPromoCodes.map((promoCode) => {
              return promoCode.valid ? (
                <StyledMiniBasketContentsRow key={promoCode.id_promotion} $promoCode>
                  <div>
                    <div>{!promoCode.auto && <div>{promoCode.code}</div>}</div>
                    <div className="remove-container">
                      {promoCode.text_to_show}
                      {!promoCode.auto && (
                        <Button
                          buttonType={ButtonType.ICON}
                          className="remove-button"
                          onClick={() => removePromoCode(promoCode)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  </div>
                </StyledMiniBasketContentsRow>
              ) : (
                postcodeItem && (
                  <div className="mb-2 text-[var(--color-danger)] text-sm">
                    <strong>{promoCode.code}</strong> has not been appled to your basket as your
                    address is not eligible for this promotion.
                  </div>
                )
              );
            })}
          </>
        )}
        <StyledMiniBasketContentsRow $lastChild>
          <div className="row__total-costs">
            <div className="row__total-costs__column">
              <strong>Monthly costs</strong>
              <p>{`£${getFormattedPrice(totalMonthlyCost)}`}</p>
            </div>
            <div className="row__total-costs__column">
              <strong>Admin fee</strong>
              <p>{`£${getFormattedPrice(oneOffChargesDiscounted)}`}</p>
            </div>
          </div>
        </StyledMiniBasketContentsRow>
      </StyledMiniBasketContents>
      <PromoCode />
    </>
  );
};
