import type { AppProps } from 'next/app';
import Script from 'next/script';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import TagManager from 'react-gtm-module';

import { Layout } from '@components/Organisms';

import { Theme } from '@theme/index';

import 'add-to-calendar-button/assets/css/atcb.css';
import '../styles/globals.css';
import '../styles/fonts.css';

import {
  BasketProvider,
  FooterProvider,
  HeaderProvider,
  PostcodeProvider,
  PromoProvider,
  PromotionsProvider,
  SalesCountdownProvider,
} from '@providers/index';
import { saveConversionTrackerRequestId, storeAWCCookieForAWIN } from '@lib/conversionTracker';
import * as process from 'process';
import { CheckoutProvider } from '@providers/CheckoutProvider/CheckoutProvider';
import { DataLayerProvider } from '@providers/DataLayerProvider';

import { GA_TRACKING_ID } from '@lib/utils/gtag';
import { css } from 'styled-components';

const tagManagerArgs = {
  gtmId: 'GTM-58QWD25',
};

declare global {
  interface Window {
    SR: any;
  }
}

function App({ Component, pageProps }: AppProps) {
  const { events, asPath, query } = useRouter();

  useEffect(() => {
    const handleRouteChange = async (url: URL) => {
      window?.SR.event.pageVisit().then(function () {
        window?.SR.dynamicContent.get();
      });
      saveConversionTrackerRequestId();
    };
    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    saveConversionTrackerRequestId();
  }, []);
  useEffect(() => {
    if (query.awc) {
      storeAWCCookieForAWIN(query.awc);
    }
  }, [query.awc]);

  useEffect(() => {
    if (window && window.location.hostname === 'node.preview-hey-broadband.heybroadband.co.uk') {
      markerSDK.loadWidget({
        project: '64f74ba6a1ffbabf07a937e4', // <- Your unique project ID
        ssr: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          renderDelay: 3000, // 0 - 15000 (ms)
        },
      });
    }
  }, []);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://code.tidio.co/bnhd3f6xzntfw1wc1nhf712xquqwqlsu.js"
      />
      <Script
        strategy="beforeInteractive"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      {/* <Script
        strategy="afterInteractive"
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="a2b50e1f-6c3c-45c3-8c15-5abe51713fa7"
        data-blockingmode="auto"
        type="text/javascript"
        async
      /> */}
      <Script
        id="Tiktok"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
        var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
        ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


          ttq.load('CQP3PIJC77U5M191G110');
          ttq.page();
        }(window, document, 'ttq');`,
        }}
      />
      <Script
        id="Visa"
        dangerouslySetInnerHTML={{
          __html: `(function(v,i,s,a,t){v[t]=v[t]||function(){(v[t].v=v[t].v||[]).push(arguments)};if(!v._visaSettings){v._visaSettings={}}v._visaSettings[a]={v:'1.0',s:a,a:'1',t:t};var b=i.getElementsByTagName('body')[0];var p=i.createElement('script');p.defer=1;p.async=1;p.src=s+'?s='+a;b.appendChild(p)})(window,document,'//app-worker.visitor-analytics.io/main.js','da38850b-9c4c-11eb-b589-901b0edac50a','va')`,
        }}
      />
      <Theme />
      <Head>
        {!!parseInt(process.env.NEXT_PUBLIC_NO_INDEX || '0') && (
          <meta name="robots" content="noindex,nofollow" />
        )}
        {pageProps?.data?.page?.forece24Tracker && process.env.NODE_ENV === 'production' && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function (f, o, r, c, e, _2, _4) {
              f.Force24Object = e, f[e] = f[e] || function () {
                f[e].q = f[e].q || [], f[e].q.push(arguments)
              }, f[e].l = 1 * new Date, _2 = o.createElement(r),
              _4 = o.getElementsByTagName(r)[0], _2.async = !0, _2.src = c, _4.parentNode.insertBefore(_2, _4)
            })(window, document, "script", "https://static.websites.data-crypt.com/scripts/activity/v3/inject-v3.min.js", "f24");

            f24('config', 'set_tracking_id', 'f04ca9e7-a32d-4b95-8908-e804d15778af');
            f24('config', 'set_client_id', 'a3940827-2e94-4684-88bf-fc6ba451f76f');`,
            }}
          />
        )}
        <title>{pageProps?.metadata?.title || 'Hey! Broadband'}</title>
        {pageProps?.metadata?.richSnippets && pageProps?.metadata?.richSnippets.display && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'Service',
                'serviceType': pageProps?.metadata?.richSnippets?.serviceType,
                'provider': {
                  '@type': 'LocalBusiness',
                  'name': 'Hey!Broadband',
                },
                'areaServed': {
                  '@type': 'Place',
                  'name': pageProps?.metadata?.richSnippets?.areaServed,
                  'telephone': pageProps?.metadata?.richSnippets?.telephone,
                },
                'product': {
                  '@type': 'Product',
                  'name': pageProps?.metadata?.richSnippets?.description,
                  'aggregateRating': {
                    '@type': 'AggregateRating',
                    'ratingValue': pageProps?.metadata?.richSnippets?.qualification,
                    'reviewCount': '1',
                  },
                },
              }),
            }}
          />
        )}
        <meta
          name="description"
          content={
            pageProps?.metadata?.description ||
            'Hey!Broadband | Enjoy the fastest, most reliable full fibre straight to your home or business.'
          }
        />
        <meta name="keywords" content={pageProps?.metadata?.keywords || ''} />
        {process.env.NEXT_PUBLIC_BUILD_SHA && (
          <meta name="version" content={process.env.NEXT_PUBLIC_BUILD_SHA} />
        )}
        <link rel="icon" href="/favicon.png" />
        <style>
          {css`
            .hide-tidio #tidio-chat-iframe {
              display: none !important;
            }
          `}
        </style>
      </Head>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}');
        `}
      </Script>
      <DataLayerProvider>
        <PostcodeProvider
          featureList={pageProps?.featureList}
          registerInterestData={pageProps?.registerInterestData}
        >
          <PromotionsProvider promotionsData={{ promotions: [] }}>
            <HeaderProvider headerData={pageProps?.headerData}>
              <PromoProvider promoData={pageProps?.promoData}>
                <SalesCountdownProvider salesCountdownData={pageProps?.salesCountdownData}>
                  <FooterProvider footerData={pageProps?.footerData}>
                    <BasketProvider products={pageProps?.data?.products}>
                      <CheckoutProvider>
                        <Layout
                          path={asPath}
                          pageProps={pageProps}
                          {...(pageProps?.layoutProps || {})}
                        >
                          <Component {...pageProps} />
                        </Layout>
                      </CheckoutProvider>
                    </BasketProvider>
                  </FooterProvider>
                </SalesCountdownProvider>
              </PromoProvider>
            </HeaderProvider>
          </PromotionsProvider>
        </PostcodeProvider>
      </DataLayerProvider>
      <Script id="synerise" strategy="afterInteractive">
        {`
          function onSyneriseLoad() {
            SR.init({
              'trackerKey': '1730001c-fd32-4afd-8882-05928ea011d9',
              'trackingDomain': 'https://devsyn.heybroadband.co.uk',
              'customPageVisit': true,
              'dynamicContent': {
                'virtualPage': true
              }
            });

            SR.event.pageVisit()
                .then(function () {
                    SR.dynamicContent.get();
            })
        }
          (function(s,y,n,e,r,i,se){s['SyneriseObjectNamespace'] = r;s[r]=s[r]||[],
          s[r]._t=1*new Date(),s[r]._i=0,s[r]._l=i;var z=y.createElement(n),
          se=y.getElementsByTagName(n)[0];z.async=1;z.src=e;se.parentNode.insertBefore(z,se);
          z.onload=z.onreadystatechange=function(){var rdy=z.readyState;
          if(!rdy||/complete|loaded/.test(z.readyState)){s[i]();z.onload = null;
        z.onreadystatechange=null;}};})
          (window,document,'script','//devsyn.heybroadband.co.uk/prtefxg0.js','SR', 'onSyneriseLoad');
        `}
      </Script>
    </>
  );
}

export default App;
