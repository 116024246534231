import pino from 'pino';

import {
  EROWFACTORY,
  EGLOBALCONTENTCONTROLLER,
  EPAGECONTROLLER,
  EPRODUCTCONTROLLER,
  EAPIROUTES,
  EGEOCODECONTROLLER,
} from './types';

export const LOG = {
  ERROR: {
    FACTORY: {
      ROW: EROWFACTORY,
    },
    CONTROLLER: {
      GEOCODE: EGEOCODECONTROLLER,
      GLOBAL_CONTENT: EGLOBALCONTENTCONTROLLER,
      PAGE: EPAGECONTROLLER,
      PRODUCT: EPRODUCTCONTROLLER,
      BLOGS: {
        GET_ALL_BLOGS: 'GET_ALL_BLOGS',
        GET_BLOG: 'GET_BLOG',
        BLOGS_BY_CATEGORY: 'BLOGS_BY_CATEGORY',
      },
      CATEGORY: {
        GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
      },
    },
    ROUTES: {
      API: EAPIROUTES,
    },
  },
};

export const logger = pino({
  prettyPrint: true,
});
