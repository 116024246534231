import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';
import CheckWhite from '@public/icons/Check-white.svg';
import BennyThumbsUp from '@public/Benny_Thumbs_up@2x.png';

export const FilterChipStyle = css`
  display: block;
  cursor: pointer;
  background: transparent;
  color: var(--colors-grey-100);
  position: relative;
  margin-right: ${rem(12)};
  box-shadow: 0 0 0 1.5px var(--colors-primary-30);

  @media ${breakpoints.tablet} {
    display: block;
    margin-right: ${rem(16)};
  }

  &.active {
    box-shadow: 0 0 0 1.5px var(--colors-primary);
    background: var(--colors-primary);
    color: var(--colors-white);
  }
`;

export const StyledAccordionBackground = styled.div<{ showBackground: boolean }>`
  ${({ showBackground }) =>
    showBackground &&
    `
    @media ${breakpoints.desktop} {
      background-image: url('${BennyThumbsUp.src}');
      background-position: right bottom;
      background-size: 55%;
      background-repeat: no-repeat;
      min-height: 905px;
      align-items: flex-start;
    }
  `}
`;

export const StyledAccordion = styled.div<{ alignment: string; theme: string }>`
  ${({ alignment }) => alignment && `margin: 0 auto;`}
  .accordion {
    &__title {
      margin-bottom: 2rem;
    }
    &__filters {
      margin-bottom: ${rem(40)};
      display: flex;
      justify-content: flex-start;
      width: fit-content;
      flex-wrap: wrap;

      @media ${breakpoints.tablet} {
        flex-wrap: nowrap;
      }
    }
    &__filter {
      ${FilterChipStyle}
    }

    &__unfilter {
      border-bottom: 2px solid transparent;
      color: var(--colors-grey-100);
      transition: all 0.2s ease;
      margin-top: ${rem(12)};
      @media ${breakpoints.tablet} {
        margin-top: 0;
      }
      &.active {
        border-color: var(--colors-grey-50);
      }
    }

    &__items {
      > :last-child {
        border-bottom: none;
      }
    }
  }
`;
