import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';

import { AccordionItem, Button, Chip } from '@components/Atoms';

import { fromMarkdown } from '@lib/utils';

import { ChipType } from '@components/Atoms/Chip/types';
import { ButtonType } from '@components/Atoms/Button/types';
import { Props, AccordionItemData, CategoryChip } from './types';
import { CategoryType } from '@components/Molecules/Accordion/types';

import { StyledAccordion, StyledAccordionBackground } from './styled';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const STYLED = {
  wrapper: styled.section<{ sTheme: string }>`
    ${({ sTheme }) =>
      sTheme === 'bgFadedBlue'
        ? `
        background-color: var(--color-lightest-blue);

      h2 {
        color: black;
        font-family: var(--font-geomanist);
        font-weight: 600;
        font-size: 2.5rem;
        margin-bottom: 2rem;

        @media ${breakpoints.desktop} {
          margin-bottom: 6rem;
        }
      }
    `
        : ''};
  `,
};

export const Accordion: FC<Props> = ({ data }) => {
  const {
    CTA,
    heading,
    FAQ,
    showFilters,
    showBackground,
    isSimple,
    alignment = 'left',
    theme = 'default',
  } = data || {};

  function initialFilteredList(showFilters) {
    if (showFilters) {
      return FAQ.slice(0, 4).sort((a, b) => a.question?.localeCompare(b.question));
    } else {
      return FAQ;
    }
  }

  const [filteredList, setFilteredList] = useState(initialFilteredList(showFilters));
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const [seeAll, setSeeAll] = useState<boolean>(true);
  const [openKey, setOpenKey] = useState<string | null>();

  useEffect(() => {
    setFilteredList(FAQ);
  }, [FAQ]);

  const handleToggle = (key: string | null) => {
    setOpenKey(openKey !== key ? key : null);
  };

  const resetAll = () => {
    setCurrentFilter('');
    setActive('');
    setFilteredList(FAQ);
    setSeeAll(true);
    setOpenKey(null);
  };

  const filterItem = (selected: CategoryType) => {
    const filteredList: AccordionItemData[] = [];

    if (currentFilter !== selected) {
      setCurrentFilter(selected);
      FAQ.forEach((item) => {
        if (item.category.includes(selected)) {
          filteredList.push(item);
        }
      });
      if (seeAll) setSeeAll(false);
      filteredList.sort((a, b) => a.question?.localeCompare(b.question));
      setFilteredList(filteredList);
    }
  };

  const categories = [
    {
      label: 'Installation',
      category: CategoryType.INSTALLATION,
    },
    {
      label: 'Speed',
      category: CategoryType.SPEED,
    },
    {
      label: 'Equipment',
      category: CategoryType.EQUIPMENT,
    },
    {
      label: 'Phone',
      category: CategoryType.PHONE,
    },
  ];

  return (
    <STYLED.wrapper className="accordion slice" sTheme={theme}>
      <StyledAccordionBackground className="slice__inner" showBackground={!!showBackground}>
        <StyledAccordion
          className={isSimple ? 'xl:w-1/2 md:w-1/1 sm:w-1/1 mx-auto' : 'xl:w-1/2 md:w-1/1 sm:w-1/1'}
          alignment={alignment}
        >
          {heading && (
            <h2
              className={`text-${alignment} ${
                isSimple ? 'text-black font-black text-xl mb-8' : 'slice__title'
              }`}
            >
              {fromMarkdown({ text: data.heading })}
            </h2>
          )}
          {showFilters && (
            <div className="accordion__filters">
              {categories &&
                categories.map((category: CategoryChip) => {
                  return (
                    <Chip
                      chipType={ChipType.PRIMARY}
                      as="button"
                      className={`accordion__filter ${
                        active === category.category ? 'active' : ''
                      }`}
                      onClick={() => {
                        filterItem(category.category);
                        setActive(category.category);
                        setOpenKey(null);
                      }}
                      key={category.category}
                    >
                      {category.label}
                    </Chip>
                  );
                })}
              {categories && (
                <button
                  id="all"
                  className={`accordion__unfilter ${seeAll ? 'active' : ''}`}
                  onClick={() => resetAll()}
                >
                  See All
                </button>
              )}
            </div>
          )}
          {filteredList && (
            <div className="accordion__items mb-10">
              {filteredList.map((item: AccordionItemData, index) => {
                return (
                  item.display && (
                    <AccordionItem
                      key={index}
                      toggle={handleToggle}
                      open={openKey === item.question}
                      heading={item.question}
                      body={item.answer}
                      {...item}
                    />
                  )
                );
              })}
            </div>
          )}

          {CTA && (
            <div className="w-full flex justify-center">
              <Link href={CTA.link} passHref>
                <Button buttonType={ButtonType.PRIMARY}>
                  <a href={CTA.link}>{CTA.label}</a>
                </Button>
              </Link>
            </div>
          )}
        </StyledAccordion>
      </StyledAccordionBackground>
    </STYLED.wrapper>
  );
};
