import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

const StyledSubTitle = styled.h2<{ color?: string }>`
  color: ${(props) => props.color || `var(--color-primary)`};
  line-height: 1;
  margin-top: 0.5rem;
  font-size: ${rem(30)};

  @media (${breakpoints.desktopLarge}) {
    font-size: ${rem(40)};
    margin-top: 0;
    max-width: 100%;
    margin-bottom: 0;
  }
`;

export { StyledSubTitle };
