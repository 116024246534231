import styled, { css } from 'styled-components';

interface Props {
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  xs?: boolean;
  xxs?: boolean;
}

const StyledIcon = styled.img<Props>`
  display: block;

  // Return md sizing if no size is given
  width: 42px;
  height: 42px;

  ${({ xxs, xs, sm, md, lg }: Props) => {
    if (lg) {
      return css`
        width: 64px;
        height: 64px;
      `;
    }
    if (md) {
      return css`
        width: 42px;
        height: 42px;
      `;
    }
    if (sm) {
      return css`
        width: 36px;
        height: 36px;
      `;
    }
    if (xs) {
      return css`
        width: 24px;
        height: 24px;
      `;
    }
    if (xxs) {
      return css`
        width: 1.25rem;
        height: 1.25rem;
      `;
    }
  }}
`;

export { StyledIcon };
