import React, { useState, useEffect, FC } from 'react';
import Image from 'next/image';

import { renderImage } from '@lib/utils/index';
import { Format } from '@lib/types';

import { StyledIcon } from './styled';

interface IconProps {
  icon: any;
  className?: string;
  xs?: boolean;
  xxs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  altText?: string;
}

export const DynamicIcon: FC<any> = ({ icon, width, height, ...rest }) => {
  const [src, setSrc] = useState('#');
  const [iconWidth, setIconWidth] = useState<string>('42px');
  const [iconHeight, setIconHeight] = useState<string>('42px');
  // Dynamically load image src from icons.
  useEffect(() => {
    async function _import() {
      const importedSrc = (await import(`@public/icons/${icon}.svg`))?.default;

      if (importedSrc) {
        setSrc(importedSrc);
      }

      if (width) {
        setIconWidth(width);
      }

      if (height) {
        setIconHeight(height);
      }
    }

    if (typeof icon === 'string') _import();
  }, [icon, width, height]);

  if (src && src !== '#') {
    return <Image src={src} alt={icon} width={iconWidth} height={iconHeight} {...rest} />;
  }

  if (icon?.url) {
    return renderImage({
      formats: icon.formats,
      format: Format.THUMBNAIL,
      image: icon,
      alternativeText: icon.name.substr(0, icon.name.lastIndexOf('.')),
      ...rest,
    });
  }

  return null;
};

export const Icon = (props: IconProps) => {
  return <StyledIcon {...props} src={props.icon?.src || props.icon} alt={props.altText || ''} />;
};
