/**
 * Product Controller
 *
 * @description Provides interaction with the product API service
 *
 *  NOTE:  API spec available at: https://oss-test.apx-gis.com:4092/docs/api/
 */

import { AbortController } from 'node-abort-controller';
import queryString from 'query-string';

import { LOG, logger } from '@lib/logger';

import {
  Action,
  ActionArgsT,
  CustomerInfoT,
  DirectDebitArgsT,
  GoCardlessDirectDebitArgsT,
  ProductT,
  PromoT,
  ScheduleInfoT,
  ScheduleResT,
  UprnDataT,
  UprnItemT,
} from '@lib/types';

/**
 * @var root Root url for the product controller's requests
 */
const root = process.env.HB_PRODUCT_API_ROOT_URL;
const token = process.env.HB_PRODUCT_API_TOKEN ?? '';

/**
 * Builds and returns the parameters for the http request
 *
 * @param {string | undefined} method The http request method
 * @param {any | undefined} body The body of the request
 * @returns { method: string; body: any; headers: Headers; } The http request params
 */
export const getParams = ({
  method = 'GET',
  body = undefined,
  timeout = 20000,
}: {
  method?: string;
  body?: any;
  timeout?: number;
}): {
  method: string;
  body: any;
  headers: Headers;
  signal: AbortSignal;
} => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeout);

  return {
    method,
    body,
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-token': token,
    }),
    signal: controller.signal,
  };
};

export const getParamsServer = ({
  method = 'GET',
  body = undefined,
  timeout = 20000,
}: {
  method?: string;
  body?: any;
  timeout?: number;
}): {
  method: string;
  body: any;
  headers: Headers;
  signal: AbortSignal;
} => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeout);

  return {
    method,
    body,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    signal: controller.signal,
  };
};

/**
 * Wrapper for fetching JSON and throwing error if not a status of 200.
 *
 * @param {string} url
 *   The request URL excluding the URL root
 * @param {object} params
 *   Additional parameters to the request
 *
 * @return {object|Error}
 *   JSON object or array of return data, or an error if not status 200
 */
const fetchJson = async (url, params = {}, throwError = true) => {
  const response = await fetch(url, params);
  if (response.status !== 200) {
    const json = await response.json();

    if (throwError) {
      throw new Error(json?.error || response.statusText);
    } else {
      console.error(json?.error || response.statusText);
      return { error: json?.error || response.statusText };
    }
  }
  return response.json();
};

/**
 * Fetches the list of available products
 *
 * @returns {ProductT[]} the list of available products
 */
export const getProducts = async (nameToMatch = ''): Promise<never[] | ProductT[] | null> => {
  try {
    const url = `${root}/product`;
    const products = await fetchJson(url, getParams({}));

    return products.filter(
      ({ name, enabled_for_web = false }) => enabled_for_web || nameToMatch === name,
    );
  } catch (err) {
    logger.warn(err, LOG.ERROR.CONTROLLER.PRODUCT.GET_PRODUCTS);

    return null;
  }
};

/**
 * Fetches the list of active promotions
 *
 * @returns {PromoT[]} The list of active promotions
 */
export const getActivePromos = async (args): Promise<never[] | PromoT[] | null> => {
  try {
    const url = `${root}/promotion?register_channel=WEB&${queryString.stringify(args)}`;
    const promos = await fetchJson(url, getParams({}));

    return promos;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.GET_ACTIVE_PROMOS);

    return null;
  }
};

/**
 * Fetches the list of active promotions
 *
 * @returns {PromoT[]} The list of active promotions
 */
export const getActivePromosServer = async (
  args: {
    code?: string;
    postcode?: string;
    register_channel?: string;
    product?: string;
  } = {},
): Promise<never[] | PromoT[] | null> => {
  try {
    const url = `/api/active-promos?${queryString.stringify(args)}`;

    const promos = await fetchJson(url, getParams({}));

    return promos;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.GET_ACTIVE_PROMOS);

    return null;
  }
};

/**
 * Fetches a given promotion by its code
 *
 * @param {string} code The promo code
 * @param {string} product The product on the promo will be applied
 * @param {string} postcode The postcode on the promo will be applied
 * @returns {PromoT} The promotion data
 */
export const getPromoByCode = async ({
  code,
  ...rest
}: {
  code: string;
  product?: string;
  postcode?: string;
}): Promise<PromoT> => {
  try {
    const url = `${root}/promotion/${code}?register_channel=WEB&${queryString.stringify(rest)}`;
    const promo = await fetchJson(url, getParams({}));

    return promo;
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_PROMO_BY_CODE);

    return null;
  }
};

/**
 * Fetches a given promotion by its code (via Server)
 *
 * @param {string} code The promo code
 * @returns {PromoT} The promotion data
 */
export const getPromoByCodeServer = async (args: {
  code: string;
  product?: string | null;
  postcode?: string;
}): Promise<PromoT> => {
  try {
    const url = `/api/get-promo?${queryString.stringify(args)}`;
    const promo = await fetchJson(url, getParams({}));

    return promo;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.GET_PROMO_BY_CODE);

    return null;
  }
};

/**
 * Fetches a given promotion by its postcode
 *
 * @param {string} postcode The postcode
 * @returns {PromoT} The promotion data
 */
export const getPromosByPostcode = async ({
  postcode,
}: {
  postcode: string;
}): Promise<PromoT[]> => {
  try {
    const url = `${root}/promotion/promotionByPostcode/${postcode}`;

    return await fetchJson(url, getParams({}));
  } catch (err) {
    logger.error({ postcode, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_PROMO_BY_POSTCODE);

    return [];
  }
};

/**
 * Fetches a given promotion by its postcode (Via server)
 *
 * @param {string} postcode The postcode
 * @returns {PromoT} The promotion data
 */
export const getPromosByPostcodeServer = async ({
  postcode,
}: {
  postcode: string;
}): Promise<PromoT[]> => {
  try {
    const url = `api/promotion-by-postcode/${postcode}`;

    return await fetchJson(url, getParamsServer({}));
  } catch (err) {
    logger.error({ postcode, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_PROMO_BY_POSTCODE);

    return [];
  }
};

/**
 * Fetches a list of uprns by postcode
 *
 * @param {string} postcode The postcode
 * @returns {UprnItemT[]} The list of uprns
 */
export const getUprnList = async ({
  postcode,
}: {
  postcode: string;
}): Promise<never[] | UprnItemT[] | null> => {
  try {
    const url = `${root}/uprn/uprnByPostcode/${postcode.toUpperCase()}`;
    const uprns = await fetchJson(url, getParams({}));

    return uprns;
  } catch (err) {
    logger.error({ postcode, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_UPRN_LIST);

    throw err;
  }
};

/**
 * Fetches a list of uprns by postcode (via the next.js backend API)
 *
 * @param {string} postcode The postcode
 * @returns {UprnItemT[]} The list of uprns
 */
export const getUprnListServer = async ({
  postcode,
}: {
  postcode: string;
}): Promise<never[] | UprnItemT[] | null> => {
  try {
    const url = `/api/uprn-by-postcode`;
    const uprns = await fetchJson(
      url,
      getParams({ method: 'POST', body: JSON.stringify({ postcode }) }),
    );

    return uprns;
  } catch (err) {
    logger.error({ postcode, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_UPRN_LIST);

    throw err;
  }
};

/**
 * Get uprn data by uprn
 *
 * @param {string} uprn The uprn number
 * @returns {UprnDataT} The uprn data
 */
export const getUprnData = async ({ uprn }: { uprn: string }): Promise<UprnDataT> => {
  try {
    const url = `${root}/uprn/${uprn}`;
    return await fetchJson(url, getParams({}));
  } catch (err) {
    logger.error({ uprn, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_UPRN_DATA);

    return null;
  }
};

/**
 * Get uprn data by uprn (via the next.js backend API)
 *
 * @param {string} uprn The uprn number
 * @returns {UprnDataT} The uprn data
 */
export const getUprnDataServer = async ({
  uprn,
}: {
  uprn: string | null | undefined;
}): Promise<UprnDataT> => {
  try {
    const url = `/api/uprn`;
    return await fetchJson(url, getParams({ method: 'POST', body: JSON.stringify({ uprn }) }));
  } catch (err) {
    logger.error({ uprn, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_UPRN_DATA);

    throw err;
  }
};

export const setUprnCheckedServer = async (uprn: string) => {
  try {
    const url = `api/uprn-checked`;

    await fetchJson(
      url,
      getParamsServer({
        method: 'PUT',
        body: JSON.stringify({ uprn }),
      }),
    );
  } catch (err) {
    logger.error({ uprn, err }, 'SERVER');
  }
};

/**
 * Get uprn data by uprn
 *
 * @param {string} uprn The uprn number
 */
export const setUprnChecked = async (uprn: string) => {
  try {
    const url = `${root}/uprn/uprnChecked/${uprn}`;

    return await fetchJson(
      url,
      getParams({
        method: 'PUT',
      }),
    );
  } catch (err) {
    logger.error({ uprn, err }, 'Normal');
  }
};

/**
 * Check postcode against external API.
 *
 * @todo Replace this with something better than postcodes.io.
 * @todo Define typescript type when provider is finalised.
 *
 * @param {string} postcode
 *
 * @return {object | Error}
 *   Object of postcode response data.
 */
export const checkPostcode = async ({ postcode }) => {
  try {
    const response = await fetchJson(`https://api.postcodes.io/postcodes/${postcode}`);
    if (response?.status !== 200) {
      throw new Error(response.error);
    }
    return response.result;
  } catch (err) {
    throw err;
  }
};

/**
 * Do some action (SIGNUP, ROI, PREORDER, LEAD, PROSPECT, UPDATE).
 *
 * @param {Action} action The chosen action to perform
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const doAction = async ({
  action,
  args,
}: {
  action: Action;
  args: ActionArgsT;
}): Promise<any> => {
  try {
    const url = `${root}/customer/${action}`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ action, args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "SIGNUP".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerSignup = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  return await doAction({ action: Action.SIGNUP, args });
};

export const customerSignupServer = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  try {
    const url = '/api/customer-signup';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Fetch Prospect code
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerProspect = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  try {
    const url = `${root}/customer/PROSPECT`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

export const customerProspectServer = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  try {
    const url = '/api/customer-prospect';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "UPDATE".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const preActiveCustomerUpdate = async ({
  args,
  code,
}: {
  args: ActionArgsT;
  code: string;
}): Promise<any> => {
  try {
    const url = `${root}/customer/${code}`;

    const data = await fetchJson(
      url,
      getParams({
        method: 'PUT',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.SET_DIRECT_DEBIT);

    throw err;
  }
};

export const preActiveCustomerUpdateServer = async ({
  args,
  code,
}: {
  args: ActionArgsT;
  code: string;
}): Promise<any> => {
  try {
    const url = `/api/preactive-customer-update?code=${code}`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "UPDATE".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerUpdate = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  return await doAction({ action: Action.UPDATE, args });
};

export const customerUpdateServer = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  try {
    const url = '/api/customer-update';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "PREORDER".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerPreorder = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  return await doAction({ action: Action.PREORDER, args });
};

export const customerPreorderServer = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  try {
    const url = '/api/customer-preorder';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "PREORDERDDSDU".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerPreorderDDSDU = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  return await doAction({ action: Action.PREORDERDDSDU, args });
};

export const customerPreorderDDSDUServer = async ({
  args,
}: {
  args: ActionArgsT;
}): Promise<any> => {
  try {
    const url = '/api/customer-preorderDDSDU';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "PREORDERDDSDU".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerPreorderDDMDU = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  return await doAction({ action: Action.PREORDERDDMDU, args });
};

export const customerPreorderDDMDUServer = async ({
  args,
}: {
  args: ActionArgsT;
}): Promise<any> => {
  try {
    const url = '/api/customer-preorderDDMDU';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Proxy for the doAction with action of "ROI".
 *
 * @param {ActionArgsT} args The arguments to the action
 * @returns {any} OK if successful otherwise throws error
 */
export const customerRegisterInterest = async ({ args }: { args: ActionArgsT }): Promise<any> => {
  return await doAction({ action: Action.ROI, args });
};

export const customerRegisterInterestServer = async ({
  args,
}: {
  args: ActionArgsT;
}): Promise<any> => {
  try {
    const url = '/api/customer-roi';
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ args, err }, LOG.ERROR.CONTROLLER.PRODUCT.DO_ACTION);
    throw err;
  }
};

/**
 * Get customer info by code.
 *
 * @param {string} code The customer code
 * @returns {CustomerInfoT} The customer information
 */
export const getCustomerInfo = async ({ code }: { code: string }): Promise<CustomerInfoT> => {
  try {
    const url = `${root}/customer/${code}`;
    const info = await fetchJson(url, getParams({}));

    return info;
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_CUSTOMER_INFO);

    return null;
  }
};

/**
 * Action renew contract
 *
 * @param {string} customerId The id of the customer
 * @param {string} contractId The id of the contract to renew
 * @returns {any} OK if successful otherwise throws error
 */
export const renew = async ({ customerId, contractId }): Promise<any> => {
  try {
    const url = `${root}/customer/renew`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: {
          id_customer: customerId,
          id_contract_renew: contractId,
        },
      }),
    );

    return data;
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.RENEW);

    return null;
  }
};

/**
 * Setup direct debit
 *
 * @param {string} code The customer code
 * @param {string} account_name The customer account name
 * @param {string} account_number The customer account number
 * @param {string} sort_code The customer sort code
 * @returns OK if successful otherwise throws error
 */
export const setDirectDebit = async ({
  code,
  account_name,
  account_number,
  sort_code,
}: DirectDebitArgsT) => {
  try {
    const url = `${root}/customer/${code}/direct-debit`;

    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify({
          account_name,
          account_number,
          sort_code,
        }),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.SET_DIRECT_DEBIT);

    throw err;
  }
};

/**
 * Setup direct debit (via the next.js backend API)
 *
 * @param {string} code The customer code
 * @param {string} account_name The customer account name
 * @param {string} account_number The customer account number
 * @param {string} sort_code The customer sort code
 * @returns OK if successful otherwise throws error
 */
export const setDirectDebitServer = async ({
  code,
  account_name,
  account_number,
  sort_code,
}: DirectDebitArgsT) => {
  try {
    const url = `/api/direct-debit`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify({
          code,
          account_name,
          account_number,
          sort_code,
        }),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.SET_DIRECT_DEBIT);

    throw err;
  }
};

/**
 * Fetches data for schedule by work order id
 *
 * @param {string} workorderId The id of the worker order
 * @returns The data for schedule
 */
export const getScheduleInfo = async ({
  workorderId,
}: {
  workorderId: string;
}): Promise<ScheduleInfoT> => {
  try {
    const url = `${root}/workorder/schedule/${workorderId}`;
    const data = await fetchJson(url, getParams({}));

    return data;
  } catch (err) {
    logger.error({ workorderId, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_SCHEDULE_INFO);

    return null;
  }
};

/**
 * Makes request to set appointment by work order id
 *
 * @param {string} workorderId The id of the worker order
 * @returns {ScheduleResT} The response from the request
 */
export const setScheduleInfo = async ({
  workorderId,
  day,
  time,
}: {
  workorderId: string;
  day: string;
  time: string;
}): Promise<ScheduleResT> => {
  try {
    const url = `${root}/workorder/schedule/${workorderId}`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'PUT',
        body: { day, time },
      }),
    );

    return data;
  } catch (err) {
    logger.error({ workorderId, err }, LOG.ERROR.CONTROLLER.PRODUCT.SET_SCHEDULE_INFO);

    return null;
  }
};

export const outageServer = async (email: string) => {
  try {
    const url = `api/outage`;

    return await fetchJson(
      url,
      getParamsServer({
        method: 'POST',
        body: JSON.stringify({ email }),
      }),
    );
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.OUTAGESERVER);

    throw err;
  }
};

export const outage = async (email: string) => {
  try {
    const url = `${root}/customer/outage`;

    return await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify({ email }),
      }),
    );
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.OUTAGE);

    throw err;
  }
};

export const getInstallationSlotsServer = async (code: string) => {
  try {
    const url = `/api/installation-slots`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify({
          code,
        }),
      }),
      false,
    );

    return data;
  } catch (err) {
    logger.error({ err }, LOG.ERROR.CONTROLLER.PRODUCT.INSTALLATION_SLOTS_SERVER);

    throw err;
  }
};

/**
 * Get available installation slots by UPRN which is stored in postcode context
 *
 * @param {string} code - Unique Property Reference Number
 * @returns {ScheduleResT} The response from the request
 */
export const getInstallationSlots = async (code: string) => {
  try {
    const url = `${root}/customer/${code}/schedule`;

    return await fetchJson(
      url,
      getParams({
        method: 'GET',
      }),
      false,
    );
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.INSTALLATION_SLOTS);

    throw err;
  }
};

export const customerGetGoCardlessUrlServer = async ({
  args,
}: {
  args: GoCardlessDirectDebitArgsT;
}) => {
  try {
    const url = `/api/upfront-payment-request`;

    return await fetchJson(
      url,
      getParamsServer({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.GOCARDLESS_URL_SERVER);

    throw err;
  }
};

export const customerGetGoCardlessUrl = async ({ args }: { args: GoCardlessDirectDebitArgsT }) => {
  try {
    const { code } = args;
    const url = `${root}/customer/${code}/direct-debit-request`;

    delete args.code;

    return await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify(args),
      }),
    );
  } catch (err) {
    logger.error(err, LOG.ERROR.CONTROLLER.PRODUCT.GOCARDLESS_URL);

    throw err;
  }
};
/**
 * Get customer data by code / order number
 *
 * @param {string} code The customer code
 * @returns {CustomerInfoT} The customer data
 */
export const getCustomerByCode = async ({ code }: any): Promise<CustomerInfoT> => {
  try {
    const url = `${root}/customer/${code}`;
    return await fetchJson(url, getParams({}));
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_UPRN_DATA);

    return null;
  }
};

/**
 * Get customer data by code / order number
 *
 * @param {string} code The customer code
 * @returns {CustomerInfoT} The customer data
 */
export const getCustomerByCodeServer = async ({
  code,
}: {
  code: string | null | undefined;
}): Promise<CustomerInfoT> => {
  try {
    const url = `/api/customer-get-by-code/`;
    return await fetchJson(url, getParams({ method: 'POST', body: JSON.stringify({ code }) }));
  } catch (err) {
    logger.error({ code, err }, LOG.ERROR.CONTROLLER.PRODUCT.GET_UPRN_DATA);

    throw err;
  }
};

export const setInstallationScheduleServer = async ({
  id_workorder,
  time,
  day,
}: {
  id_workorder: string;
  time: string;
  day: string;
}) => {
  try {
    const url = `/api/set-installation-schedule`;
    const data = await fetchJson(
      url,
      getParams({
        method: 'POST',
        body: JSON.stringify({
          id_workorder,
          time,
          day,
        }),
      }),
    );

    return data;
  } catch (err) {
    logger.error({ err }, LOG.ERROR.CONTROLLER.PRODUCT.INSTALLATION_SLOTS_SERVER);

    throw err;
  }
};

export const setInstallationSchedule = async ({
  id_workorder,
  time,
  day,
}: any): Promise<CustomerInfoT> => {
  try {
    const url = `${root}/workorder/schedule/${id_workorder}`;

    const uprns = await fetchJson(
      url,
      getParams({ method: 'PUT', body: JSON.stringify({ time, day }) }),
    );

    return uprns;
  } catch (err) {
    logger.error({ err }, LOG.ERROR.CONTROLLER.PRODUCT.INSTALLATION_SLOTS_SERVER);

    throw err;
  }
};
