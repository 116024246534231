/* eslint-disable react/display-name */
import React, { FC } from 'react';
import Image from 'next/image';
import { match, makeSrc, fromMarkdown, renderImage, renderParagraphs } from '@lib/utils';

import { VideoPlayer } from '@components/Atoms';

import { Format, Layout } from '@lib/types';
import { Props, MediaType } from './types';

import { StyledCTA, StyledMediaBlock } from './styled';
import {
  Waves,
  WavesInverted,
  BabyWave,
  WaveWrapper,
  Wave,
} from '@components/Organisms/Common/styled';

const videoExt = [
  '.mp4',
  '.m4p',
  '.m4v',
  '.webm',
  '.mkv',
  '.flv',
  '.ogv',
  '.ogg',
  '.gif',
  '.gifv',
  '.avi',
  '.mov',
  '.qt',
  '.wmv',
  '.amv',
  '.m4v',
  '.mpg',
  '.mpeg',
  '.m2v',
];

const imageExt = [
  '.svg',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.png',
  '.webp',
  '.avif',
  '.apng',
];

const isVideo = (media: any): boolean => videoExt.includes(media?.ext.toLowerCase());
const isImage = (media: any): boolean => imageExt.includes(media?.ext.toLowerCase());

export const MediaBlock: FC<Props> = ({ data }) => {
  const { heading, body, media, cta, showWaves = false, image } = data || {};

  const root = process.env.NEXT_PUBLIC_CMS_MEDIA_ROOT_URL;
  const src = makeSrc({ root, url: media?.url });
  const imagePositionDesktop = image?.imagePositionDesktop ? image.imagePositionDesktop : 'center';
  const imagePositionMobile = image?.imagePositionMobile ? image.imagePositionMobile : 'center';
  const imageWidthDesktop = image?.imageWidthDesktop ? image.imageWidthDesktop : 40;
  const imageWidthMobile = image?.imageWidthMobile ? image.imageWidthMobile : 40;

  const GetImageRender: FC<any> = () => {
    if (media?.ext !== '.svg') {
      return renderImage({
        className: `media-block__image`,
        alternativeText: media?.alternativeText,
        formats: media?.formats,
        format: Format.SMALL,
        layout: Layout.RESPONSIVE,
      });
    } else {
      return (
        <div className="media-block__image-svg">
          <Image
            width="100%"
            height="100%"
            quality={100}
            src={src}
            layout={Layout.FILL}
            alt={media.alternativeText}
          />
        </div>
      );
    }
  };

  let mediaType: MediaType;

  if (isVideo(media)) mediaType = MediaType.VIDEO;

  if (isImage(media)) mediaType = MediaType.IMAGE;

  const RenderMedia: FC<any> = () =>
    media &&
    match(mediaType)
      .on(
        () => mediaType === MediaType.VIDEO,
        () => <VideoPlayer data={{ url: src }} />,
      )
      .on(
        () => mediaType === MediaType.IMAGE,
        () => <GetImageRender />,
      )
      .otherwise(() => <></>);

  return (
    <section className={showWaves ? 'my-40  md:my-72' : ''}>
      {showWaves && (
        <WavesInverted>
          <WaveWrapper>
            <Wave />
            <Wave />
            <Wave />
            <BabyWave />
          </WaveWrapper>
        </WavesInverted>
      )}

      <StyledMediaBlock
        className="container mx-auto py-12"
        showWaves={showWaves}
        imagePositionDesktop={imagePositionDesktop}
        imagePositionMobile={imagePositionMobile}
        imageWidthDesktop={imageWidthDesktop}
        imageWidthMobile={imageWidthMobile}
      >
        <>
          {heading && (
            <h1 className="large mb-4 w-3/4 mx-auto text-center">
              {fromMarkdown({ text: heading })}
            </h1>
          )}
          {body && (
            <p className="text-center pb-8 w-2/3 mx-auto">
              {renderParagraphs({ body, hasMarkdown: true })}
            </p>
          )}
          {image?.display && (
            <div className="media-block__image_container">
              {renderImage({
                className: `media-block__image`,
                alternativeText: image.image?.alternativeText,
                formats: image.image?.formats,
                format: Format.SMALL,
                layout: Layout.RESPONSIVE,
              })}
            </div>
          )}
          <RenderMedia />
          {cta && (
            <div className="media-block__buttons">
              <StyledCTA
                label={cta.label}
                link={cta.link}
                display={cta.display}
                buttonType={cta.buttonType}
                event={cta.event}
              />
            </div>
          )}
        </>
      </StyledMediaBlock>

      {showWaves && (
        <Waves className="pt-20 flex flex-col justify-center relative mb-8 md:mb-60 lg:pt-32 xl:pt-24">
          <WaveWrapper>
            <Wave />
            <Wave />
            <Wave />
            <BabyWave />
          </WaveWrapper>
        </Waves>
      )}
    </section>
  );
};
