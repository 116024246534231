import { CardPackageT } from '@components/Organisms/ChoosePackage/types';
import usePersistState from '@hooks/usePersistState';
import { ProductT, PromoT } from '@lib/types';
import { PriceT } from '@lib/utils/getFormattedPrice/types';
import { createContext, FC, useCallback } from 'react';
import * as gtag from '@lib/utils/gtag';

type ProductTWithCalculatedPricing<t> = Partial<t> & {
  originalPrice: any;
  monthlyPriceCalculated: any;
};
export interface DataLayerParametersI {
  currency: string;
  value: PriceT;
  selected_product: ProductTWithCalculatedPricing<ProductT> | null;
  selected_promotions: PromoT[];
  extras: gtag.GA4ItemI[];
  add_extra: ProductT | null;
  remove_extra: ProductT['id_product'];
  originalPrice: number;
  monthlyPriceCalculated: number;
  items: gtag.GA4ItemI[];
  viewed_items: CardPackageT[];
  promotion_name: string;
  OrderID: string;
  OrderValue: string;
  ProductType: string;
  Extras: string;
  OrderStatus: string;
  OrderLocation: string;
  CommissionGroup: string;
  PromoCodes: string;
  AWC: string;
}

interface ContextI {
  parameters: DataLayerParametersI;
}

const initialParams = {
  currency: 'GBP',
  value: 0,
  selected_product: null,
  selected_promotions: [],
  extras: [],
  viewed_items: [],
  promotion_name: '',
  add_extra: null,
  remove_extra: '',
  originalPrice: 0,
  monthlyPriceCalculated: 0,
  items: [],
  OrderID: '',
  OrderValue: '',
  ProductType: '',
  Extras: '',
  OrderStatus: '',
  OrderLocation: '',
  CommissionGroup: '',
  PromoCodes: '',
  AWC: '',
};

export const DataLayerContext = createContext<ContextI>({
  parameters: { ...initialParams },
});

export const DataLayerProvider: FC = ({ children }) => {
  const fourHours = 60 * 60 * 1000 * 4;
  const oneDay = 24 * 60 * 60 * 1000;
  const STATE_EXPIRY_TIME = process.env.NODE_ENV === 'development' ? oneDay : fourHours;

  const [parameters, _setParameters] = usePersistState<DataLayerParametersI>(
    { ...initialParams },
    'dataLayerParameters',
    STATE_EXPIRY_TIME,
  );

  const setParameters = useCallback(
    (parameters: DataLayerParametersI) => {
      _setParameters((prevParameters: DataLayerParametersI) => {
        return {
          ...prevParameters,
          ...parameters,
        };
      });
    },
    [_setParameters],
  );

  const data = {
    parameters,
    setParameters,
  };

  return <DataLayerContext.Provider value={data}>{children}</DataLayerContext.Provider>;
};
