import React, { FC, Key } from 'react';
import { StepsInfoT } from '@factories/RowFactory/components/StepsInfo/types';
import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { renderComponents } from '@lib/utils';
import { InfoStep } from '@components/Molecules/InfoStep/InfoStep';
import Link from 'next/link';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const STYLED = {
  heading: styled.h2<{ sTheme: 'default' | 'withIcons' }>`
  color: var(--color-primary);
  line-height: 1;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 3rem;

  ${({ sTheme }) =>
    sTheme === 'withIcons' &&
    `
    color: black;
    padding: 0 10vw;
    text-align: center;

    @media ${breakpoints.desktop} {
      font-size: 2.5rem;
      font-weight: 500;
    }
  `}}
  `,
  wrapper: styled.section<{ sTheme: 'default' | 'withIcons' }>`
  ${({ sTheme }) =>
    sTheme === 'withIcons' &&
    `
    padding: 4rem 0;
    background-image: linear-gradient(to left, rgb(244 17 27 / 10%) 0%, rgb(4 2 253 / 20%) 200%);
    @media ${breakpoints.desktop} {

      padding: 6rem 0;
    }
  `}}
  `,
};

export const StepsInfo: FC<StepsInfoT> = ({
  display,
  title,
  textButton,
  infoSteps,
  theme = 'default',
}) => {
  if (!infoSteps.length || !display) return null;

  const infoStepsComponent = infoSteps?.map(
    ({ label, display, instructions, icon = null }, key: Key) => {
      return (
        display && (
          <InfoStep
            number={parseInt(key.toString()) + 1}
            label={label}
            instructions={instructions}
            icon={icon}
          />
        )
      );
    },
  );

  return (
    <STYLED.wrapper className="slice pb-0" sTheme={theme}>
      <div className="slice__inner flex-col">
        <STYLED.heading sTheme={theme}>{title}</STYLED.heading>
        <div className="infoStep flex flex-col md:flex-row flex-wrap  items-start justify-center">
          {renderComponents({ components: infoStepsComponent })}
        </div>
        {!!textButton && (
          <div className="flex justify-center">
            <Link passHref href="#choose-package-section">
              <Button buttonType={ButtonType.PRIMARY} as="a">
                {textButton}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </STYLED.wrapper>
  );
};
